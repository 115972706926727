import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useLanguage, langPrefix } from './language-context'
import { media } from '../styles/media-query-template';
import { AnalyticsService, GACategories } from '../services/analytics-service';
import healthcareSrc from '../images/home/satisfaction_patient.svg';
import airTravelSrc from '../images/home/satisfaction_passenger.svg';
import cleaningSrc from '../images/home/satisfaction_cleaning.png';
import convenienceRetailSrc from '../images/home/satisfaction_customer.svg';
import { solutionOptions } from '../components/header';

const SolutionLayout = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;

  ${media.large`
    margin-inline-start: 16px;

    &:first-child {
      margin-inline-start: 0px;
    }
  `}
`;

const SolutionImageWrapper = styled.div`
  background-color: rgba(193, 243, 223, 0.5);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SolutionImage = styled.img`
  flex: 0 1 auto;
  margin: 0 auto;
`;

const SolutionButton = styled(Link)`
  font-size: 1em;
  border: 1px solid #1f2d54;
  padding: 16px;
  border-radius: 2px;
  margin-top: 16px;
  cursor: pointer;
  text-decoration: none;
  color: #000000;

  ${media.large`
    margin-top: 24px;
    flex-direction: row;
  `}
`;

const Events = {
  passenger: 'Passenger Satisfaction',
  customer: 'Customer Satisfaction',
  employee: 'Employee Satisfaction',
  patient: 'Patient Satisfaction',
  student: 'Student Satisfaction'
};

const Solution = ({text, link, src, solution}) => {
  return (
    <SolutionLayout>
      <SolutionImageWrapper><SolutionImage src={src}/></SolutionImageWrapper>
      <SolutionButton to={link}
        onClick={AnalyticsService.trackCustomEvent}
        data-ec={GACategories.HomePage}
        data-ea={Events[solution]}
        data-el={Events[solution]}>{text}</SolutionButton>
    </SolutionLayout>
  )      
};

const SolutionsLayout = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 120px;
  font-size: 1rem;

  ${media.large`
    flex-direction: row;
  `}
`;

const Solutions = useLanguage(({msg}) => {
  
  return (
    <SolutionsLayout>
      <Solution text={msg(solutionOptions[0][1])} src={airTravelSrc} link={solutionOptions[0][0]} solution='air-travel' />
      <Solution text={msg(solutionOptions[1][1])} src={cleaningSrc} link={solutionOptions[1][0]} solution='air-travel' />
      <Solution text={msg(solutionOptions[2][1])} src={convenienceRetailSrc} link={solutionOptions[2][0]} solution='convenience-retail' />
      <Solution text={msg(solutionOptions[3][1])} src={healthcareSrc} link={solutionOptions[3][0]} solution='healthcare' />
    </SolutionsLayout>
  )
})

export { Solutions }
