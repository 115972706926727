import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { langPrefix, useLanguage } from './language-context';
import { media } from '../styles/media-query-template';
import { GACategories } from '../services/analytics-service';

import heroImage from '../images/home/hero_background.png';
import heroSmileyBoxImage from '../images/home/hero_touchless_sb.png';

import { mkEventEffect } from './utils';
import { useIsMobile } from '../hooks/layout';
import { navigate } from 'gatsby';

const headerHeight = '80px';
const paddingTop = '56px';
const paddingBottom = '40px';

const HeroDisplay = styled.div`
  padding: ${paddingTop} 32px ${paddingBottom};
  height: calc(${props => props.vh} - ${headerHeight} - ${paddingBottom} - ${paddingBottom});
  min-height: 390px;
  background-image: url(${heroImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  display: flex;
  flex-direction: column;
  font-size: 1rem;

  ${media.medium`
    height: 770px;
  `}

  ${media.large`
    padding: 50px 60px;
  `}

  ${media.min1550`
    padding: 50px 80px;
  `}
`;

const LargeScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 auto;
  ${media.medium`
    margin-top: 0;
    justify-content: center;
  `}
`;

const HeroText = styled.div`
  margin-bottom: 24px;
  color: #FFFFFF;
  font-size: 1.5em;
  font-weight: bold;
  order: 0;

  ${media.medium`
    font-size: 3.123em;
  `}
`;

const HeroDivider = styled.div`
  margin: 16px 0;
  width: 100px;
  height: 8px;
`;

const SubText = styled.div`
  font-size: 1.125rem;
  ${media.large`
    font-size: 1.5rem;
  `}
`;

const MobileFlipWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1 0 auto;

  ${media.medium`
    flex: 0 1 auto;
  `}
`;

const RequestDemoButton = styled.button`
  padding: 8px 24px;
  border: solid 2px #FFFFFF;
  border-radius: 2px;
  background-color: #78BE20;
  color: #FFFFFF;
  font-size: 1em;
  font-weight: 600;
  align-self: inherit;
  order: 1;
  cursor: pointer;

`;

const HeroSmileyBoxImage = styled.img`
  width: 152px;
  align-self: flex-end;
  order: 2;

  ${media.medium`
    width: 431px;
  `}
`;

const DiscoverPart = styled.div`
  display: none;
  ${media.large`
    display: flex;
    width: 100%;
    align-items: center;
    flex-flow: column;
  `}
`

const DiscoverText = styled.div`
  display: flex;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
`

const DownArrow = styled.img`
  display: flex;
  width: 32px;
  margin-top: 10px;
  cursor: pointer;
`
const Hero = useLanguage(({lang, msg, hiddenBanner}) => {
  const [vh, setVH] = useState('');
  const isMobile = useIsMobile()

  const calcVh = () => {
    const minimalHeight = 450;
    let offset = hiddenBanner ? 0 : 100;
    let result = (window.innerHeight < minimalHeight ? minimalHeight : window.innerHeight) - offset;
    setVH(result + 'px');
  }

  const handleResize = () => {
    calcVh();
  }

  useEffect(mkEventEffect('resize', handleResize), []);

  useEffect(() => {
    calcVh();
  }, [hiddenBanner]);

  const goToRequestDemo = () => {
    navigate(langPrefix(lang, '/request-demo'));
  }

  return (
    <HeroDisplay vh={vh}>
      <LargeScreenWrapper>
        <HeroText>
          {msg('home-tagline-1')}<br/>
          {msg('home-tagline-2')}
          <HeroDivider />
          <SubText>
            {msg('home-tagline-3')} 
            {isMobile ? ' ':  <br />}
            {msg('home-tagline-4')}
          </SubText>
        </HeroText>
        <MobileFlipWrapper>
          <RequestDemoButton onClick={goToRequestDemo} data-ec={GACategories.HomePage} data-ea='Request a Demo' data-el='Request a Demo'>{msg('home-demo')}</RequestDemoButton>
          <HeroSmileyBoxImage src={heroSmileyBoxImage} />
        </MobileFlipWrapper>
      </LargeScreenWrapper>
    </HeroDisplay>
  );
});

export { Hero };