import React from "react"
import styled from "styled-components"
import { useLanguage } from "./language-context"

import adrLogo from "../images/home/clients/adr-logo.png"
import bradleyLogo from "../images/home/clients/bradley-logo.png"
import carmilaLogo from "../images/home/clients/carmila-logo.png"
import gsrLogo from "../images/home/clients/gsr-logo.png"
import hugLogo from "../images/home/clients/hug-logo.png"
import mecLogo from "../images/home/clients/mec-logo.png"
import nestleLogo from "../images/home/clients/nestle-logo.png"
import nsaLogo from "../images/home/clients/nsa-logo.png"
import pluieLogo from "../images/home/clients/pluie-logo.png"
import seaLogo from "../images/home/clients/sea-logo.png"
import starlingLogo from "../images/home/clients/starling-logo.png"
import svicomLogo from "../images/home/clients/svicom-logo.png"
import { media } from "../styles/media-query-template"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Text = styled.div`
  font-size: 18px !important;
  font-family: Lato;
  margin: 50px 0 50px 0;
  ${media.medium`
    font-size: 35px !important;
    line-height: 46px;
  `}
`

const LogosGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  ${media.medium`
    grid-template-columns: repeat(6, 1fr);
  `}
`

const Logo = styled.img`
  align-self: center;
  justify-self: center;
`

const Clients = useLanguage(({ msg }) => {
  return (
    <Wrapper>
      <Text>{msg("home-clients-text")}</Text>
      <LogosGrid>
        <Logo src={adrLogo} />
        <Logo src={carmilaLogo} />
        <Logo src={bradleyLogo} />
        <Logo src={gsrLogo} />
        <Logo src={hugLogo} />
        <Logo src={mecLogo} />
        <Logo src={nsaLogo} />
        <Logo src={nestleLogo} />
        <Logo src={pluieLogo} />
        <Logo src={seaLogo} />
        <Logo src={starlingLogo} />
        <Logo src={svicomLogo} />
      </LogosGrid>
    </Wrapper>
  )
})

export { Clients }
