import { useEffect, useState } from 'react';
import { mkEventEffect } from '../components/utils';
import { mediaWidths } from '../styles/media-query-template';

export function useIsMobile() {
  const [isMobileView, setIsMobileView] = useState(false);

  const handleResize = () => {
    setIsMobileView(window.innerWidth < mediaWidths.medium);
  }
  useEffect(() => {
    handleResize();
  }, []);
  useEffect(mkEventEffect("resize", handleResize), []);

  return isMobileView
}