import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Layout } from '../components/layout';
import { media } from '../styles/media-query-template';
import { Hero } from '../components/hero-section';
import { Offerings } from '../components/offering-section';
import { useLanguage, passLanguage } from '../components/language-context';
import { Section } from '../components/section';
import { RequestForm, FormType } from '../components/request-form';
import { Solutions } from '../components/solutions-section';
import { AnalyticsService } from '../services/analytics-service';
import SEO from '../components/seo';

import seoImage from '../images/home/FBN_SEO.png';
import { HeaderService } from '../services/header-service';
import { InfoBannerService } from '../services/info-banner-service';
import { Clients } from '../components/clients-section';

/* 

NOTES:

import SEO from "../components/seo"

and put this as the first thing after the layout

    <SEO title="Home" />

*/

const OfferingsText = styled.div`
  align-self: center;
  line-height: 60px;
  font-size: 2.5em;
  margin-top: 43px;
  margin-bottom: 44px;
`;

const HorizontalLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Panel = styled.div`
  width: 100%;

  ${media.medium`
    max-width: 50%;
    flex: 0 0 50%;
  `}
`;

const IndexPageBase = useLanguage(({location, lang, msg}) => {

  const [hiddenBanner, setHiddenBanner] = useState(false);

  const handleBannerVisibilityChanged = (hidden) => {
    setHiddenBanner(hidden);
  }

  const _title = 'FeedbackNow by Forrester';
  const _description = 'Real-time customer feedback solutions. Measure, analyze, and improve on CX feedback, across all physical and digital touchpoints along the customer journey.';

  return (
    <Layout onBannerVisibilityChanged={handleBannerVisibilityChanged} location={location}>
      <SEO
        title={_title}
        description={_description}
        image={seoImage}
      />
      <Hero hiddenBanner={hiddenBanner} />
      
      <Section title={msg('home-offering')} showLine={true} lang={lang}>
        <Offerings page='home'/>
        <OfferingsText>{msg('real-time')}</OfferingsText>
      </Section>
      <Section id='clients' title={msg('home-clients')} showLine={true} lang={lang}>
        <Clients />
      </Section>
      <Section id='solutions' title={msg('home-solutions')} showLine={true} lang={lang}>
        <Solutions />
      </Section>
    </Layout>
  );
});

const IndexPage = passLanguage(IndexPageBase)


export default IndexPage
